import { ErrorFields } from 'interfaces/error-fields';
import { ConnectionStepProps } from 'interfaces/step-props';
import { SupportedConnectionType } from 'interfaces/supported-connection-type';
import { FC } from 'react';
import {
  AdfsAccessPolicy,
  AdfsClaimsPolicy,
  AdfsConfigureRelyingPartyTrust,
  AdfsProvideEndpoint,
  AdfsTokenSigningCertificate,
  AdpOidcConfigureRedirectUri,
  AdpOidcCreateProject,
  AdpOidcProvideClientId,
  AdpOidcUploadCertificate,
  Auth0CreateApplication,
  Auth0UploadMetadata,
  AzureAssignPeople,
  AzureCreateApplication,
  AzureLoginUrl,
  AzureSamlConfiguration,
  AzureSigningCertificate,
  AzureUserAttributes,
  CyberArkAddUsers,
  CyberArkConfigureAttributeStatements,
  CyberArkCreateIntegration,
  CyberArkUploadMetadata,
  DuoSamlConfigureClaims,
  DuoSamlConfigureResponseSettings,
  DuoSamlCreateCloudApplication,
  DuoSamlCreateConfiguration,
  DuoSamlEnterAcsUrl,
  DuoSamlProvideEntityId,
  DuoSamlProvideSsoEndpoint,
  DuoSamlUploadCertificate,
  GenericSamlConfigureClaims,
  GenericSamlCreateApplication,
  GenericSamlProvideIdpInformation,
  GenericSamlProvideSpIdentity,
  GenericSamlProvideSsoEndpoint,
  GenericSamlUploadCertificate,
  GoogleSamlAddApplication,
  GoogleSamlAttributeMapping,
  GoogleSamlEnterDetails,
  GoogleSamlServiceProviderDetails,
  GoogleSamlUploadIdpInformation,
  GoogleSamlUserAccess,
  JumpCloudConfigureAttributeStatements,
  JumpCloudCreateApplication,
  JumpCloudSubmitUrlEntityId,
  JumpCloudUploadMetadata,
  OktaAddUsers,
  OktaApplicationFeedback,
  OktaAttributeStatements,
  OktaIdentiyProviderMetadata,
  OktaOINConfigureIntegration,
  OktaSAMLIntegration,
  OneLoginAdvancedConfiguration,
  OneLoginBasicConfiguration,
  OneLoginConfigureParameters,
  OneLoginCreateApplication,
  OneLoginOACConfigureCustomerId,
  OneLoginOACProvideEndpoint,
  OneLoginOACSelectApplication,
  OneLoginOACUploadCertificate,
  OneLoginUploadMetadata,
  OpenIDCreateApplication,
  OpenIDProvideClientCredentials,
  OpenIDProvideDiscoveryEndpoint,
  OpenIDProvideInformation,
  PingFederateConfigureAssertionCreation,
  PingFederateConfigureBrowserSettings,
  PingFederateConfigureCredentials,
  PingFederateConfigureProtocolSettings,
  PingFederateCreateConnection,
  PingFederateUploadData,
  PingOneConfigureAttributeStatements,
  PingOneCreateIntegration,
  PingOneSubmitAcsUrl,
  PingOneUploadMetadata,
  RipplingConfigureAttributeMapping,
  RipplingCreateApplication,
  RipplingInputIdpDetails,
  RipplingUploadAcsUrl,
  VMWareAdvancedConfiguration,
  VMWareBasicConfiguration,
  VMWareConfigureAttributeMap,
  VMWareCreateApplication,
  VMWareProvideLaunchUrl,
  VMWareUploadCertificate,
} from './';

export interface StepConfig {
  errorFields: ErrorFields;
  providerLabel: string;
  steps: { name: string; Component: FC<ConnectionStepProps> }[];
}

type Overrides = 'OneLoginOAC' | 'OktaOIN';

type StepsConfig = {
  [key in SupportedConnectionType]: StepConfig;
};

type StepsConfigOverrides = {
  [key in Overrides]: StepConfig;
};

export const stepsConfig: StepsConfig = {
  OktaSAML: {
    errorFields: {
      saml_idp_url: {
        label: 'Identity Provider Single Sign-On URL',
        step: 5,
      },
      saml_entity_id: {
        label: 'Identity Provider Issuer',
        step: 5,
      },
      saml_x509_certs: {
        label: 'X.509 Certificate',
        step: 5,
      },
      saml_idp_metadata_url: {
        label: 'X.509 Certificate',
        step: 5,
      },
    },
    providerLabel: 'Okta',
    steps: [
      { name: 'Create SAML Integration', Component: OktaSAMLIntegration },
      {
        name: 'Configure Attribute Statements',
        Component: OktaAttributeStatements,
      },
      {
        name: 'Submit Application Feedback',
        Component: OktaApplicationFeedback,
      },
      { name: 'Add Users to SAML App', Component: OktaAddUsers },
      {
        name: 'Upload Identity Provider Metadata',
        Component: OktaIdentiyProviderMetadata,
      },
    ],
  },
  GoogleSAML: {
    errorFields: {
      saml_x509_certs: {
        label: 'X.509 Certificate',
        step: 3,
      },
    },
    providerLabel: 'Google SAML',
    steps: [
      {
        name: 'Add Custom SAML Application',
        Component: GoogleSamlAddApplication,
      },
      {
        name: 'Enter Details for your Custom App',
        Component: GoogleSamlEnterDetails,
      },
      {
        name: 'Upload Google IdP Information',
        Component: GoogleSamlUploadIdpInformation,
      },
      {
        name: 'Enter Service Provider Details',
        Component: GoogleSamlServiceProviderDetails,
      },
      {
        name: 'Configure Attribute Mapping',
        Component: GoogleSamlAttributeMapping,
      },
      { name: 'Configure User Access', Component: GoogleSamlUserAccess },
    ],
  },
  AzureSAML: {
    errorFields: {
      saml_x509_certs: {
        label: 'Certificate',
        step: 4,
      },
      saml_idp_url: {
        label: 'Login URL',
        step: 5,
      },
    },
    providerLabel: 'Azure AD',
    steps: [
      {
        name: 'Create Enterprise Application',
        Component: AzureCreateApplication,
      },
      {
        name: 'Configure Attribute Statements',
        Component: AzureSamlConfiguration,
      },
      { name: 'User Attributes & Claims', Component: AzureUserAttributes },
      { name: 'Assign People & Groups', Component: AzureAssignPeople },
      {
        name: 'SAML Signing Certificate',
        Component: AzureSigningCertificate,
      },
      { name: 'Provide a Login URL', Component: AzureLoginUrl },
    ],
  },
  ADFSSAML: {
    errorFields: {
      saml_x509_certs: {
        label: 'Token Signing Certificate',
        step: 3,
      },
      saml_idp_url: {
        label: 'SAML 2.0 Endpoint',
        step: 4,
      },
    },
    providerLabel: 'Microsoft AD FS',
    steps: [
      {
        name: 'Configure a Relying Party Trust',
        Component: AdfsConfigureRelyingPartyTrust,
      },
      { name: 'Choose Access Policy', Component: AdfsAccessPolicy },
      {
        name: 'Configure Claims Issuance Policy',
        Component: AdfsClaimsPolicy,
      },
      {
        name: 'Upload Token Signing Certificate',
        Component: AdfsTokenSigningCertificate,
      },
      { name: 'Provide SAML 2.0 Endpoint', Component: AdfsProvideEndpoint },
    ],
  },
  GenericSAML: {
    errorFields: {
      saml_entity_id: {
        label: 'SP Entity ID',
        step: 3,
      },
      saml_x509_certs: {
        label: 'Certificate',
        step: 4,
      },
      saml_idp_url: {
        label: 'IdP SSO Endpoint',
        step: 5,
      },
    },
    providerLabel: 'Generic SAML',
    steps: [
      {
        name: 'Provide IdP Information',
        Component: GenericSamlProvideIdpInformation,
      },
      {
        name: 'Create a Generic SAML Application',
        Component: GenericSamlCreateApplication,
      },
      {
        name: 'Provide an SP Entity ID',
        Component: GenericSamlProvideSpIdentity,
      },
      {
        name: 'Upload your X.509 Certificate',
        Component: GenericSamlUploadCertificate,
      },
      {
        name: 'Provide your IdP SSO Endpoint',
        Component: GenericSamlProvideSsoEndpoint,
      },
      {
        name: 'Configure your SAML Claims',
        Component: GenericSamlConfigureClaims,
      },
    ],
  },
  DuoSAML: {
    errorFields: {
      saml_entity_id: {
        label: 'SP Entity ID',
        step: 3,
      },
      saml_x509_certs: {
        label: 'Certificate',
        step: 4,
      },
      saml_idp_url: {
        label: 'IdP SSO Endpoint',
        step: 5,
      },
    },
    providerLabel: 'Duo SAML',
    steps: [
      {
        name: 'Create an SSO Configuration in Duo',
        Component: DuoSamlCreateConfiguration,
      },
      {
        name: 'Create a Cloud Application in Duo',
        Component: DuoSamlCreateCloudApplication,
      },
      {
        name: 'Provide the Entity ID in Duo',
        Component: DuoSamlProvideEntityId,
      },
      {
        name: 'Upload the X.509 Certificate from Duo',
        Component: DuoSamlUploadCertificate,
      },
      {
        name: 'Provide the IdP SSO Endpoint from Duo',
        Component: DuoSamlProvideSsoEndpoint,
      },
      { name: 'Enter the ACS URL in Duo', Component: DuoSamlEnterAcsUrl },
      {
        name: 'Configure SAML Response Settings in Duo',
        Component: DuoSamlConfigureResponseSettings,
      },
      {
        name: 'Configure the SAML Claims',
        Component: DuoSamlConfigureClaims,
      },
    ],
  },
  AdpOidc: {
    errorFields: {
      oidc_redirect_uri: {
        label: 'Redirect URI',
        step: 2,
      },
      oidc_client_id: {
        label: 'Client ID',
        step: 3,
      },
      oidc_client_secret: {
        label: 'Client Secret',
        step: 3,
      },
      oidc_ssl_cert: {
        label: 'ADP SSL Certificate',
        step: 4,
      },
      oidc_private_key: {
        label: 'ADP Private Key',
        step: 4,
      },
    },
    providerLabel: 'ADP OIDC',
    steps: [
      {
        name: 'Create a Project in the ADP Apps Portal',
        Component: AdpOidcCreateProject,
      },
      {
        name: 'Configure the Redirect URI in ADP',
        Component: AdpOidcConfigureRedirectUri,
      },
      {
        name: 'Provide the Client ID and Secret',
        Component: AdpOidcProvideClientId,
      },
      {
        name: 'Upload the SSL Certificate and Private Key',
        Component: AdpOidcUploadCertificate,
      },
    ],
  },
  JumpCloudSAML: {
    errorFields: {
      saml_idp_url: {
        label: 'Identity Provider Single Sign-On URL',
        step: 4,
      },
      saml_x509_certs: {
        label: 'X.509 Certificate',
        step: 4,
      },
      saml_idp_metadata_xml: {
        label: 'XML Metadata File',
        step: 4,
      },
    },
    providerLabel: 'JumpCloud',
    steps: [
      {
        name: 'Create SAML Application',
        Component: JumpCloudCreateApplication,
      },
      {
        name: 'Submit ACS URL and Entity ID',
        Component: JumpCloudSubmitUrlEntityId,
      },
      {
        name: 'Configure Attribute Statements',
        Component: JumpCloudConfigureAttributeStatements,
      },
      {
        name: 'Upload Identity Provider Metadata',
        Component: JumpCloudUploadMetadata,
      },
    ],
  },
  OneLoginSAML: {
    errorFields: {
      saml_x509_certs: {
        label: 'Certificate',
        step: 5,
      },
      saml_idp_url: {
        label: 'SAML 2.0 Endpoint',
        step: 5,
      },
      saml_idp_metadata_xml: {
        label: 'XML Metadata File',
        step: 5,
      },
    },
    providerLabel: 'OneLogin',
    steps: [
      {
        name: 'Create a SAML Application',
        Component: OneLoginCreateApplication,
      },
      {
        name: 'Basic SAML Configuration',
        Component: OneLoginBasicConfiguration,
      },
      {
        name: 'Advanced SAML Configuration',
        Component: OneLoginAdvancedConfiguration,
      },
      {
        name: 'Configure Parameters',
        Component: OneLoginConfigureParameters,
      },
      {
        name: 'Upload Metadata',
        Component: OneLoginUploadMetadata,
      },
    ],
  },
  PingOneSAML: {
    errorFields: {
      saml_idp_url: {
        label: 'Identity Provider Single Sign-On URL',
        step: 5,
      },
      saml_x509_certs: {
        label: 'X.509 Certificate',
        step: 5,
      },
    },
    providerLabel: 'PingOne',
    steps: [
      {
        name: 'Create SAML Integration',
        Component: PingOneCreateIntegration,
      },
      {
        name: 'Submit ACS URL and Entity ID',
        Component: PingOneSubmitAcsUrl,
      },
      {
        name: 'Configure Attribute Statements',
        Component: PingOneConfigureAttributeStatements,
      },
      {
        name: 'Upload Identity Provider Metadata',
        Component: PingOneUploadMetadata,
      },
    ],
  },
  PingFederateSAML: {
    errorFields: {
      saml_idp_url: {
        label: 'Identity Provider Single Sign-On URL',
        step: 5,
      },
      saml_x509_certs: {
        label: 'X.509 Certificate',
        step: 5,
      },
    },
    providerLabel: 'PingFederate',
    steps: [
      {
        name: 'Create SAML SP Connection',
        Component: PingFederateCreateConnection,
      },
      {
        name: 'Configure Browser SSO Settings',
        Component: PingFederateConfigureBrowserSettings,
      },
      {
        name: 'Configure Assertion Creation',
        Component: PingFederateConfigureAssertionCreation,
      },
      {
        name: 'Configure Protocol Settings',
        Component: PingFederateConfigureProtocolSettings,
      },
      {
        name: 'Configure Credentials',
        Component: PingFederateConfigureCredentials,
      },
      { name: 'Upload IdP Data', Component: PingFederateUploadData },
    ],
  },
  VMwareSAML: {
    errorFields: {
      saml_x509_certs: {
        label: 'Certificate',
        step: 5,
      },
      saml_idp_url: {
        label: 'SAML 2.0 Endpoint',
        step: 6,
      },
    },
    providerLabel: 'VMWare Workspace ONE',
    steps: [
      {
        name: 'Create a new SaaS Application',
        Component: VMWareCreateApplication,
      },
      {
        name: 'Basic SAML Configuration',
        Component: VMWareBasicConfiguration,
      },
      {
        name: 'Advanced SAML Configuration',
        Component: VMWareAdvancedConfiguration,
      },
      {
        name: 'Configure Attribute Map',
        Component: VMWareConfigureAttributeMap,
      },
      {
        name: 'Upload X.509 Certificate',
        Component: VMWareUploadCertificate,
      },
      { name: 'Provide a Launch URL', Component: VMWareProvideLaunchUrl },
    ],
  },
  GenericOIDC: {
    errorFields: {
      oidc_redirect_uri: {
        label: 'Redirect URI',
        step: 2,
      },
      oidc_client_id: {
        label: 'Client ID',
        step: 3,
      },
      oidc_client_secret: {
        label: 'Client Secret',
        step: 3,
      },
      oidc_discovery_endpoint: {
        label: 'Discovery Endpoint',
        step: 4,
      },
    },
    providerLabel: 'OpenID Connect',
    steps: [
      {
        name: 'Provide IdP Information',
        Component: OpenIDProvideInformation,
      },
      {
        name: 'Create an Application with your IdP',
        Component: OpenIDCreateApplication,
      },
      {
        name: 'Provide your Client Credentials',
        Component: OpenIDProvideClientCredentials,
      },
      {
        name: 'Provide your Discovery Endpoint',
        Component: OpenIDProvideDiscoveryEndpoint,
      },
    ],
  },
  CyberArkSAML: {
    errorFields: {
      saml_idp_url: {
        label: 'Identity Provider Single Sign-On URL',
        step: 4,
      },
      saml_entity_id: {
        label: 'Identity Provider Issuer',
        step: 4,
      },
      saml_x509_certs: {
        label: 'X.509 Certificate',
        step: 4,
      },
    },
    providerLabel: 'CyberArk',
    steps: [
      {
        name: 'Create SAML Integration',
        Component: CyberArkCreateIntegration,
      },
      {
        name: 'Configure Attribute Statements',
        Component: CyberArkConfigureAttributeStatements,
      },
      { name: 'Add Users to SAML App', Component: CyberArkAddUsers },
      {
        name: 'Upload Identity Provider Metadata',
        Component: CyberArkUploadMetadata,
      },
    ],
  },
  RipplingSAML: {
    errorFields: {
      saml_idp_url: {
        label: 'Identity Provider Single Sign-On URL',
        step: 4,
      },
      saml_entity_id: {
        label: 'Identity Provider Issuer',
        step: 4,
      },
      saml_x509_certs: {
        label: 'X.509 Certificate',
        step: 4,
      },
    },
    providerLabel: 'Rippling',
    steps: [
      {
        name: 'Create SAML Integration',
        Component: RipplingCreateApplication,
      },
      {
        name: 'Input IdP Details',
        Component: RipplingInputIdpDetails,
      },
      {
        name: 'Upload ACS URL and SP Entity ID',
        Component: RipplingUploadAcsUrl,
      },
      {
        name: 'Configure Attribute Mapping',
        Component: RipplingConfigureAttributeMapping,
      },
    ],
  },
  Auth0SAML: {
    errorFields: {
      saml_idp_url: {
        label: 'Identity Provider Single Sign-On URL',
        step: 2,
      },
      saml_entity_id: {
        label: 'Identity Provider Issuer',
        step: 2,
      },
      saml_x509_certs: {
        label: 'X.509 Certificate',
        step: 2,
      },
    },
    providerLabel: 'Auth0',
    steps: [
      {
        name: 'Create SAML Integration',
        Component: Auth0CreateApplication,
      },
      {
        name: 'Upload Identity Provider Metadata',
        Component: Auth0UploadMetadata,
      },
    ],
  },
};

export const stepsConfigOverrides: StepsConfigOverrides = {
  OktaOIN: {
    errorFields: {},
    providerLabel: 'Okta',
    steps: [
      {
        name: 'Setup Okta Application',
        Component: OktaOINConfigureIntegration,
      },
    ],
  },
  OneLoginOAC: {
    errorFields: {
      saml_x509_certs: {
        label: 'Certificate',
        step: 3,
      },
      saml_idp_url: {
        label: 'SAML 2.0 Endpoint',
        step: 4,
      },
    },
    providerLabel: 'OneLogin',
    steps: [
      {
        name: 'Add the SAML Application',
        Component: OneLoginOACSelectApplication,
      },
      {
        name: 'Configure Customer ID in OneLogin',
        Component: OneLoginOACConfigureCustomerId,
      },
      {
        name: 'Upload X.509 Certificate',
        Component: OneLoginOACUploadCertificate,
      },
      {
        name: 'Provide SAML 2.0 Endpoint',
        Component: OneLoginOACProvideEndpoint,
      },
    ],
  },
};
